import React from "react"
import styled from "styled-components"

const H2 = styled.h2`
    font-weight: 600;
    font-size: 50px;
    color: ${props => props && props.light ? ({ theme }) => theme.white : ({ theme }) => theme.primaryColor};
    text-align: ${props => props && props.center ? "center" : "left"};
    margin: 0;

    @media (max-width: 767px) {
        font-size: 40px;
    }
`

const TitleH2 = ({children, light, center}) => {
    return (
        <H2 light={light} center={center}>{children}</H2>
    )
}

export default TitleH2;