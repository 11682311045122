import React from "react"
import styled, {keyframes} from "styled-components"

const wheel = keyframes`
  to {
		opacity: 0;
		top: 45px;
	}
`;

const ScrollDownIconContainer = styled.div`
    width: 30px;
	height: 50px;
	background-color: transparent;
	display: flex;
    position: absolute;
    right: 50%;
    bottom: 10px;
    transform: translateX(50%);
    cursor: pointer;
    div {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 300px;
        .mouse {
            width: 30px;
            height: 50px;
            border: 3px solid ${({ theme }) => theme.white};
            border-radius: 60px;
            position: relative;
            &::before {
                content: '';
                width: 10px;
                height: 10px;
                position: absolute;
                top: 7px;
                left: 50%;
                transform: translateX(-50%);
                background-color: ${({ theme }) => theme.white};
                border-radius: 50%;
                opacity: 1;
                animation: ${wheel} 2s infinite;
                -webkit-animation: ${wheel} 2s infinite;
            }
        }
    }
`;



const ScrollDownIcon = () => {
    return (
      <ScrollDownIconContainer onClick={() => window.scrollTo({
        top: window.innerHeight,
        behavior: "smooth"
    })}>
        <div>
            <div class="mouse"></div>
        </div>
      </ScrollDownIconContainer>
    )
}

export default ScrollDownIcon;
