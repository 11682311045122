import React, { useState, useEffect } from "react"
import styled from 'styled-components'
import Layout from "../layout/layout"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import CircleButton from "../components/button/circleButton";
import CardEquipe from "../components/cardEquipe"
import Animation from "../components/animation/animation"
import TitleH2 from "../components/titleH2"
import ScrollDownIcon from "../components/scrollDownIcon"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons"
import Eugenio from "../../static/staff/eugenio_maucieri.webp"
// import Mariani from "../../static/staff/erica_mariani.webp"
import Marionni from "../../static/staff/lara_marionni.webp"
import Mariotti from "../../static/staff/maria_angela_mariotti.webp"
// import Fischi from "../../static/staff/elisa_fischi.webp"
import Piaggio from "../../static/staff/barbara_piaggio.webp"
import Ippoliti from "../../static/staff/ippoliti_rachele.webp"
import Cecchi from "../../static/staff/elisa_cecchi.webp"
import Fusaroli from "../../static/staff/dimitri_fusaroli.webp"
import DeAscaniis from "../../static/staff/gabriella_de_ascaniis.webp"
import Bartolini from "../../static/staff/claudia_bartolini.webp"
import EttoreSmacchi from "../../static/staff/ettore_smacchi.webp"
import Agostinelli from "../../static/staff/anna_agostinelli.webp"
import Smacchi from "../../static/staff/smacchi_andrea.webp"
import Sciarra from "../../static/staff/sciarra_fabio_massimo.webp"
import Gradiente from "../../static/gradiente.png"
// import Dente from "../../static/dente.webp"
import Team from "../../static/team_opacity_3.webp"

const SubtitleH3 = styled.h3`
  font-weight: 300;
  font-size: 25px;
`;

// Intro Styled Components
const Intro = styled.div`
  height: 100vh;
  width: 100%;
  display: grid;
  //   background-image: url(${Gradiente});
  background-image: url(${Team});
  background-size: cover;
  align-items: center;
  position: relative;
  background-position: top;
  z-index: 1;
`;
const WrapperIntro = styled.div`
    display: grid;
    margin: 0 auto;
    width: 90%;
    grid-template-columns: 100%;
    /* grid-template-columns: 50% 50%; */
    align-items: center;
    text-align: center;
    .banner-emergency-mobile {
      display: none;
      @media (max-width: 960px) {
        display: block;
        background: ${({ theme }) => theme.primaryColor};
        position: absolute;
        top: calc(4vh + 55px);
        left: 0;
        width: 100%;
        padding: 7px 0 7px 15px;
        color: ${({ theme }) => theme.white};
        font-size: 10.5px;
        text-align: left;
        a {
          color: ${({ theme }) => theme.white};
          text-decoration: none;
          display: block;
          border: 1px solid ${({ theme }) => theme.white};
          position: absolute;
          background: ${({ theme }) => theme.primaryColor};
          border-radius: 50px;
          right: 7px;
          top: -10px;
          .contact-icon {
            color: ${({ theme }) => theme.white};
            padding: 10px;
            font-size: 30px;
          }
        }
      }
    }
    img {
      width: 70%;
      margin: auto;
    }
    h1 {
      margin: 5rem 0 0;
      color: ${({ theme }) => theme.white};
      font-weight: 300;
      font-size: 30px;
      line-height: 1.5;
      z-index: 2;
      text-transform: uppercase;
      padding: 0;
    }
    h2 {
      color: ${({ theme }) => theme.white};
      font-weight: 600;
      font-size: 50px;
      z-index: 2;
      margin: 0 0 20px;
    }

    @media (max-width: 767px) {
      grid-template-columns: 100%;
      div {
        order: 1;
        h1{
          font-size: 25px;
          margin: 4rem 0 0;
        }
        h2{
          font-size: 40px;
          margin: 0;
        }
      }
      img {
        margin-top: -50px;
        order: 2;
        width: 180px;
      }
    }
`;


// About Styled Components
const WrapperTwoColumnPadding = styled.div`
  min-height: 100vh;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: 30px;
  padding: 0 5vw;
  align-items: center;
  .aboutImage{
    margin: 7rem auto 0;
    height: auto;
    max-height: 100vh;
    width: 90%;
    -webkit-box-shadow: 8px 13px 8px 1px #CCCCCC, -14px -15px 0px 0px #41ecf1;
    -moz-box-shadow: 8px 13px 8px 1px #CCCCCC, -14px -15px 0px 0px #41ecf1;
    box-shadow: 8px 13px 8px 1px #CCCCCC, -14px -15px 0px 0px #41ecf1;
    @media (max-width: 1080px) {
      -webkit-box-shadow: 8px 13px 8px 1px #CCCCCC, -7px -8px 0px 0px  #41ecf1;
      -moz-box-shadow: 8px 13px 8px 1px #CCCCCC, -7px -8px 0px 0px  #41ecf1;
      box-shadow: 8px 13px 8px 1px #CCCCCC, -7px -8px 0px 0px  #41ecf1;
    }
  }
  @media (max-width: 767px) {
    grid-template-columns: 100%;
    margin-top: 3rem;
    .aboutImage{
      margin: 2rem auto 7rem;
      width: 100%;
    }
  }
`;

const WrapperTwoColumnFull = styled.div`
  min-height: 100vh;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: 30px;
  padding: 0 5vw 0 0;
  align-items: center;
  background: ${({ theme }) => theme.primaryColor};
  color: ${({ theme }) => theme.white};
  p{
    padding-right: 5vw;
  }
  span {
    color: white;
  }
  svg {
    stroke: white;
  }
  .aboutImage{
    height: 100vh;
    width: 100%;
  }
  @media (max-width: 767px) {
    grid-template-columns: 100%;
    padding: 0;
    margin: 0 0 4rem;
    .wrapperHeading {
      position: absolute;
      padding: 0 5vw;
      h2 {
        padding-top: 60px;
      }
      a {
        padding-bottom: 60px;
      }
    }
    .aboutImage{
      opacity: 0.3;
      min-height: 100vh;
    }
  }
`;


const WrapperFullWidth = styled.div`
  padding: 7% 5vw 0;
  .wrapperFirstAfter{
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-gap: 5%;
    margin-top: 50px;
  }
  @media (max-width: 767px) {
    .wrapperFirstAfter{
      grid-template-columns: 100%;
      grid-gap: 60px;
    }
  }
`;

// Equipe Styled Components
const Equipe = styled.div`
  padding: 0 5vw 60px;
`;

const EquipeFirstRow = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
  @media (max-width: 767px) {
    grid-template-columns: 100%;
  }
`;

const EquipeRow = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 3rem 0;
  @media (max-width: 767px) {
    display: block;
    margin: 0;
  }
`;

const WrapperGoogleReviews = styled.div`
  padding: 30px 5vw 60px;
  & .elfsight-app-6041b8d0-81ea-4ead-943d-bfb8592d316e {
    margin-top: 30px !important;
  }
  a:last-child {
      display: none !important;
  }
`;




export default function Home() {
  const [isMobile, setIsMobile] = useState(false)

  const data = useStaticQuery(graphql`
    query indexImages {
      firstImage: file(sourceInstanceName: {eq: "indexImages"}, relativePath: {eq: "dentist-tool.webp"}) {
        childImageSharp {
          fluid {
            originalName
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
      secondImage: file(sourceInstanceName: {eq: "indexImages"}, relativePath: {eq: "chi_siamo_studio_dentistico.webp"}) {
        childImageSharp {
          fluid(quality: 100) {
            originalName
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
      thirdImage: file(sourceInstanceName: {eq: "indexImages"}, relativePath: {eq: "services.webp"}) {
        childImageSharp {
          fluid(quality: 100) {
            originalName
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
      fourImage: file(sourceInstanceName: {eq: "indexImages"}, relativePath: {eq: "tecnologie.webp"}) {
        childImageSharp {
          fluid {
            originalName
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
      primaDopo1: file(sourceInstanceName: {eq: "indexImages"}, relativePath: {eq: "prima_dopo_brunetti.webp"}) {
        childImageSharp {
          fluid {
            originalName
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
      primaDopo2: file(sourceInstanceName: {eq: "indexImages"}, relativePath: {eq: "prima_dopo_carletti.webp"}) {
        childImageSharp {
          fluid {
            originalName
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
      primaDopo3: file(sourceInstanceName: {eq: "indexImages"}, relativePath: {eq: "prima_e_dopo_pertoldi.webp"}) {
        childImageSharp {
          fluid {
            originalName
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
    }
  `)

  useEffect(() => {
    if (window.innerWidth <= 960) setIsMobile(true)
  }, []);
  return (
    <Layout pageTitle="Eugenio Maucieri" pageDescription="Grazie alla nostra qualificazione e al costante aggiornamento della nostra equipe cerchiamo di offrire ai nostri pazienti la migliore qualità di cure possibili" pageKeywords="Salute, Denti, Chirurgia" pageAuthor="Eugenio Maucieri">

      <Intro>
        {/* <img src={data.firstImage.childImageSharp.base64.base64} alt="i nostri attrezzi" /> */}
        <WrapperIntro>
          <div class="banner-emergency-mobile">
            <a href="tel:+390759177098" target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon={faPhoneAlt} size="lg" className="contact-icon" />
            </a>
            PRONTO SOCCORSO DENTISTICO 24/7
          </div>
          {/* <img src={Dente} alt="Logo" /> */}
          <div>
            <h1>Studio Dentistico</h1>
            <h2>Dott. Eugenio Maucieri</h2>
            <CircleButton children={"Prenota un appuntamento"} link="/contatti" open dark />
          </div>
        </WrapperIntro>
        {/* <Shape /> */}
        <ScrollDownIcon />
      </Intro>
      <WrapperTwoColumnPadding>
        <Animation isMobile={isMobile} position="left" delay={900}>
          <div>
            <TitleH2>Chi siamo</TitleH2>
            <SubtitleH3>Niente di ciò che indossi è più importante del tuo sorriso.</SubtitleH3>
            <p>Grazie alla nostra qualificazione e al costante aggiornamento della nostra equipe cerchiamo di offrire ai nostri pazienti la migliore qualità di cure possibili. La nostra missione infatti è quella di prenderci cura del vostro sorriso, grazie alla nostra esperienza e all'utlizzo di tecniche e tecnologie all'avanguardia ci curiamo del vostro benessere dentale, cosi da prevenire e salvaguardare lo stato di salute della vostra bocca.</p>
            <CircleButton children={"Scopri di più"} link="/chi-siamo" />
          </div>
        </Animation>
        <Animation isMobile={isMobile} position="right" delay={500}>
          <Img fluid={data.secondImage.childImageSharp.fluid} alt="Eugenio e un bambino sorridente" className="aboutImage" />
        </Animation>
      </WrapperTwoColumnPadding>
      <WrapperTwoColumnFull>
        <Animation isMobile={isMobile} position="left" delay={500}>
          <Img fluid={data.thirdImage.childImageSharp.fluid} alt="I nostri attrezzi del mestiere" className="aboutImage" />
        </Animation>
        <Animation isMobile={isMobile} position="right" delay={900}>
          <div className="wrapperHeading">
            <TitleH2 light>Servizi</TitleH2>
            <SubtitleH3>One more Thing</SubtitleH3>
            <p>L'aggiornamento e lo studio sono le basi su cui si fonda il nostro studio e il nostro personale è sempre in costante aggiornamento in modo da potervi garantire l'utilizzo dei metodi piû avanguardistici per la cura dei vostri denti e delle vostre gengive cosi da riuscire a curare e prevenire l'insorgere di malattie e patologie che potebbero pregiudicare l'utilizzo del vostro apparato dentale. </p>
            <CircleButton children={"Scopri di più"} link="/i-servizi" />
          </div>
        </Animation>
      </WrapperTwoColumnFull>
      <WrapperFullWidth>
        <TitleH2 center>Prima & Dopo</TitleH2>
        <div className="wrapperFirstAfter">
          <Img fluid={data.primaDopo1.childImageSharp.fluid} alt="Prima e Dopo" />
          <Img fluid={data.primaDopo2.childImageSharp.fluid} alt="Prima e Dopo" />
          <Img fluid={data.primaDopo3.childImageSharp.fluid} alt="Prima e Dopo" />
        </div>
      </WrapperFullWidth>
      <WrapperTwoColumnPadding>
        <Animation isMobile={isMobile} position="left" delay={900}>
          <div>
            <TitleH2>Tecnologie</TitleH2>
            <SubtitleH3>Con la tecnologia gli uomini possono ottenere da sé.</SubtitleH3>
            <p>Il nostro studio dentistico si avvale delle migliori tecnologie in ambito dentale presenti oggi per offrirvi dei servizi che riescano a coprire a 360 gradi la cura del vostro apparato dentale. Utiliziamo sistemi come <b>scanner intraorali</b> per eliminare la fastidiosa pasta per impronta, oppure <b>telecamere intraorali</b> in grado di mostrarti la tua bocca.</p>
            <CircleButton children={"Scopri di più"} link="/apparecchiature" />
          </div>
        </Animation>
        <Animation isMobile={isMobile} position="right" delay={500}>
          <Img fluid={data.fourImage.childImageSharp.fluid} alt="Le nostre tecnologie" className="aboutImage" />
        </Animation>
      </WrapperTwoColumnPadding>
      <Equipe>
        <EquipeFirstRow>
          <Animation isMobile={isMobile} position="left">
            <TitleH2>I nostri professionisti</TitleH2>
          </Animation>
          <Animation isMobile={isMobile} position="top" delay={250}>
            <CardEquipe name="Eugenio MAUCIERI" work="Odontoiatra" image={Eugenio} large />
          </Animation>
        </EquipeFirstRow>
        <EquipeRow>
          <Animation isMobile={isMobile} position="top" delay={250}>
            <CardEquipe name="Anna AGOSTINELLI" work="Odontoiatra" image={Agostinelli} />
          </Animation>
          <Animation isMobile={isMobile} position="top" delay={250}>
            <CardEquipe name="Fabio Massimo SCIARRA" work="Odontoiatra" image={Sciarra} />
          </Animation>
        </EquipeRow>
        <EquipeRow>
        <Animation isMobile={isMobile} position="top" delay={250}>
            <CardEquipe name="Rachele IPPOLITI" work="Odontoiatra" image={Ippoliti} />
          </Animation>
          <Animation isMobile={isMobile} position="top" delay={250}>
            <CardEquipe name="Dimitri FUSAROLI" work="Odontoiatra" image={Fusaroli} />
          </Animation>
        </EquipeRow>
        <EquipeRow>
          <Animation isMobile={isMobile} position="top" delay={250}>
            <CardEquipe name="Ettore SMACCHI" work="Odontotecnico" image={EttoreSmacchi} />
          </Animation>
          <Animation isMobile={isMobile} position="top" delay={250}>
            <CardEquipe name="Andrea SMACCHI" work="Odontotecnico" image={Smacchi} />
          </Animation>
        </EquipeRow>
        <EquipeRow>
          <Animation isMobile={isMobile} position="top" delay={250}>
            <CardEquipe name="Barbara PIAGGIO" work="Assistente alla poltrona" image={Piaggio} />
          </Animation>
          <Animation isMobile={isMobile} position="top" delay={250}>
            <CardEquipe name="Elisa CECCHI" work="Assistente alla poltrona" image={Cecchi} />
          </Animation>
        </EquipeRow>
        <EquipeRow>
          <Animation isMobile={isMobile} position="top" delay={250}>
            <CardEquipe name="Lara MARIONNI" work="Assistente alla poltrona" image={Marionni} />
          </Animation>
        </EquipeRow>
        <EquipeRow>
        <Animation isMobile={isMobile} position="top" delay={250}>
            <CardEquipe name="Maria Angela MARIOTTI" work="Segretaria" image={Mariotti} />
          </Animation>
          <Animation isMobile={isMobile} position="top" delay={250}>
            <CardEquipe name="Claudia BARTOLINI" work="Segretaria" image={Bartolini} />
          </Animation>
        </EquipeRow>
      </Equipe>
      <WrapperGoogleReviews>
      <TitleH2 center>Cosa dicono di noi</TitleH2>
        <div id="google-reviews" class="elfsight-app-6041b8d0-81ea-4ead-943d-bfb8592d316e"></div>
      </WrapperGoogleReviews>

    </Layout>
  )
}