import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
    text-align: center;
    margin: 0 auto;
    width: 25vw;
    border-radius: 10px;

    @media (max-width: 767px) {
        width: 80vw;
        margin: 60px auto 60px;
    } 
`;

const SmallCircle = styled.div`
    width: ${props => props && props.large ? 160 + 'px' : 120 + 'px'};
    height: ${props => props && props.large ? 160 + 'px' : 120 + 'px'};
    border-radius: 50%;
    margin: auto;
    background-image: url(${props => props.image});
    background-size: cover;
    @media (max-width: 767px) {
        width: ${props => props && props.large ? 220 + 'px' : 180 + 'px'};
        height: ${props => props && props.large ? 220 + 'px' : 180 + 'px'};
    }
`;

const Name = styled.span`
    color: ${({ theme }) => theme.primaryColor};
    font-size: 16px;
    display: block;
    font-weight: 400;
    margin: 10px auto 0;
    @media (max-width: 767px) {
        font-size: 20px;
    }
`;

const Work = styled.span`
    color: ${({ theme }) => theme.secondaryColor};
    font-weight: 500;
    font-size: 10px;
    text-transform: uppercase;
    @media (max-width: 767px) {
        font-size: 14px;
    }
`;



const cardEquipe = ({ name, work, image, large }) => {
    return (
        <Wrapper>
            <SmallCircle image={image} large={large}></SmallCircle>
            <Name>{name}</Name>
            <Work>{work}</Work>
        </Wrapper>
    )
}

export default cardEquipe;